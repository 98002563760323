import * as i0 from '@angular/core';
import { Injectable, EventEmitter, inject, Component, Input, Output, HostListener, NgModule } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { Capacitor } from '@capacitor/core';
import { catchError, throwError, Subject, filter, takeUntil } from 'rxjs';
import * as i3 from '@angular/router';
import { NavigationEnd } from '@angular/router';
import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpClientModule } from '@angular/common/http';
import * as i2 from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
var LogLevel;
(function (LogLevel) {
  LogLevel["TRACE"] = "TRACE";
  LogLevel["DEBUG"] = "DEBUG";
  LogLevel["INFO"] = "INFO";
  LogLevel["WARN"] = "WARN";
  LogLevel["ERROR"] = "ERROR";
  LogLevel["FATAL"] = "FATAL";
  LogLevel["OFF"] = "OFF";
  LogLevel["ALL"] = "ALL";
})(LogLevel || (LogLevel = {}));
class AdiPlatformHeaderService {
  constructor(http) {
    this.http = http;
  }
  getHeaderHtmlContent(oktaToken, host, langCode, source) {
    const apiURL = host + '/auth/header';
    const accountNumber = localStorage.getItem('adi-selected-account-id');
    const headers = new HttpHeaders({
      Authorization: `Bearer ${oktaToken}`,
      Accept: 'text/html',
      'X-Adi-Source': source,
      'X-Adi-Trace-Id': localStorage.getItem('adi-trace-id')
    });
    return this.http.get(apiURL, {
      headers,
      responseType: 'text',
      params: {
        langCode,
        accountNumber
      }
    }).pipe(catchError(error => {
      return throwError(() => error);
    }));
  }
  loggingApiCall(host, logObj) {
    const apiURL = host + '/guest/logging';
    const headers = new HttpHeaders({
      'X-Adi-Trace-Id': localStorage.getItem('adi-trace-id')
    });
    return this.http.post(apiURL, logObj, {
      headers,
      responseType: 'text'
    }).pipe(catchError(error => {
      return throwError(() => error);
    }));
  }
  getNotificationCount(host, oktaToken, source) {
    const url = host + '/auth/users/notifications/count';
    const headers = new HttpHeaders({
      Authorization: `Bearer ${oktaToken}`,
      'X-Adi-Source': source,
      'X-Adi-Trace-Id': localStorage.getItem('adi-trace-id')
    });
    return this.http.get(url, {
      headers
    });
  }
  getNotificationList(host, oktaToken, source) {
    const url = host + '/auth/users/notification';
    const headers = new HttpHeaders({
      Authorization: `Bearer ${oktaToken}`,
      'X-Adi-Source': source,
      'X-Adi-Trace-Id': localStorage.getItem('adi-trace-id')
    });
    return this.http.get(url, {
      headers
    });
  }
  readNotification(host, oktaToken, source, notificationData) {
    const url = host + '/auth/users/notifications/mark-status';
    const headers = new HttpHeaders({
      Authorization: `Bearer ${oktaToken}`,
      'X-Adi-Source': source,
      'X-Adi-Trace-Id': localStorage.getItem('adi-trace-id')
    });
    return this.http.post(url, notificationData, {
      headers
    });
  }
  static {
    this.ɵfac = function AdiPlatformHeaderService_Factory(t) {
      return new (t || AdiPlatformHeaderService)(i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AdiPlatformHeaderService,
      factory: AdiPlatformHeaderService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AdiPlatformHeaderService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class AdiHeaderComponent {
  constructor(headerService, _sanitizer, renderer, elementRef, cd, router) {
    this.headerService = headerService;
    this._sanitizer = _sanitizer;
    this.renderer = renderer;
    this.elementRef = elementRef;
    this.cd = cd;
    this.router = router;
    this.host = '';
    this.source = '';
    this.env = '';
    this.loader = new EventEmitter();
    this.updateNotificationsList = new EventEmitter();
    this.oktaAuth = inject(OKTA_AUTH);
    this.unsubscribe$ = new Subject();
    this.htmlContent = this._sanitizer.bypassSecurityTrustHtml(this.staticHeaderContent());
    this.notifications = [];
    this.notificationCount = 0;
    this.notificationCountClicked = false;
  }
  onWindowScroll() {
    let headerMenu = document.getElementById('headerMenu');
    let menuIcon = document.querySelector('.menu-icon');
    if (headerMenu) {
      let computedStyle = window.getComputedStyle(headerMenu);
      let flexDirection = computedStyle.flexDirection;
      if (menuIcon && flexDirection === 'column') {
        headerMenu.style.display = 'none';
      }
    }
  }
  onClick(event) {
    let headerMenu = document.getElementById('headerMenu');
    let menuIcon = document.querySelector('.menu-icon');
    if (headerMenu) {
      let computedStyle = window.getComputedStyle(headerMenu);
      let flexDirection = computedStyle.flexDirection;
      if (flexDirection === 'column') {
        if (event.target !== menuIcon) {
          headerMenu.style.display = 'none';
          menuIcon.classList.remove('active');
        }
      }
    }
  }
  onResize() {
    let headerMenu = document.getElementById('headerMenu');
    let menuIcon = document.querySelector('.menu-icon');
    if (headerMenu) {
      let active = menuIcon.classList.contains('active');
      let computedStyle = window.getComputedStyle(headerMenu);
      let flexDirection = computedStyle.flexDirection;
      if (flexDirection === 'row') {
        headerMenu.style.display = 'flex';
      }
      if (flexDirection === 'column' && !active) {
        headerMenu.style.display = 'none';
      }
    }
  }
  clickOutside(event) {
    const target = event.target;
    const profileMenu = document.getElementById('myDropdown');
    const notification = document.getElementById('notificationDropdown');
    const isSvgElement = target instanceof SVGElement || false;
    let profileIconClicked = false;
    if (isSvgElement) {
      profileIconClicked = true;
    }
    const clickedInside = profileMenu.contains(target) || profileIconClicked;
    if (!clickedInside) {
      profileMenu.classList.remove('show');
      if (!this.notificationCountClicked) {
        notification.classList.remove('display');
      }
      this.cd.detectChanges();
    }
    this.notificationCountClicked = false;
  }
  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.updateNotificationCount();
    });
    this.headerService.getHeaderHtmlContent(this.oktaAuth.getAccessToken(), this.host, localStorage.getItem('adi-selected-locale'), this.source).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: res => {
        this.htmlContent = this._sanitizer.bypassSecurityTrustHtml(res);
        this.cd.detectChanges();
        setTimeout(() => {
          this.updateNotificationCount();
        }, 0);
        this.createEventListeners();
        this.createEventListenersForAuthorized();
      },
      error: err => {
        if (err.status === 401) {
          this.emitLoader(true);
          this.emitLogout();
        }
        this.htmlContent = this._sanitizer.bypassSecurityTrustHtml(this.staticHeaderContent());
        this.createEventListeners();
      }
    });
  }
  staticHeaderContent() {
    return `<style>
        #adiPlatformHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #003595;
            padding: 4px 48px;
            height: 64px;
        }

        /* Styles for the logo */
        #adiPlatformHeader .logo {
            display: flex;
            align-items: center;
        }
        
        /* Styles for the profile icon */
        #adiPlatformHeader .accountButton {
            display: flex;
            flex: auto;
            justify-content: flex-end;
        }

        #adiPlatformHeader .accountButton .profileIcon {
            cursor: pointer;
            position: relative;
        }

        #adiPlatformHeader .accountButton img {
            width: 72px;
            height: 64px;
        }

        #adiPlatformHeader .accountButton .profileIcon .dropbtn {
            background-color: transparent;
            color: white;
            margin-top: 5px;
            font-size: 16px;
            border: none;
            cursor: pointer;
        }

        /* The container <div> - needed to position the dropdown content */
        #adiPlatformHeader .accountButton .profileIcon .dropdown {
            position: relative;
            display: inline-block;
        }

        /* Dropdown Content (Hidden by Default) */
        #adiPlatformHeader .accountButton .profileIcon .dropdown .dropdown-content {
            display: none;
            position: absolute;
            min-width: 160px;
            z-index: 1;
        }

        /* Links inside the dropdown */
        #adiPlatformHeader .accountButton .profileIcon .dropdown .dropdown-content a {
            color: black;
            text-decoration: none;
            display: flex;
            align-items: center;
        }

        /* Change color of dropdown links on hover */
        #adiPlatformHeader .accountButton .profileIcon .dropdown .dropdown-content a:hover {
            border-radius: 12px;
        }

        #adiPlatformHeader .accountButton .profileIcon .dropdown .dropdown-content a svg {
            margin-right: 10px;
        }

        #adiPlatformHeader .accountButton .profileIcon .dropdown .dropdown-content a span {
            color: var(--Primary-600, var(--Primary-ace-color-primary-600, #003595));
            font-size: var(--Font-Size-S, 14px);
            font-style: normal;
            font-weight: 400;
            line-height: var(--Line-Height-Body-M, 19.6px);
        }

        .show {
            display: flex !important;
            right: 5px;
            top: 35px;
            padding: 20px;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            width: 193px;
            border-radius: 12px;

            background: var(--Neutral-ace-color-neutral-100, #fff);
            /* Elevation/Blue/200 */
            box-shadow: 0px 5px 20px 0px rgba(0, 53, 149, 0.15);
        }
            .hidden {
    display: none;
}

        @media screen and (min-width: 1441px) {
            #adiPlatformHeader {
                padding: 5px calc((100vw - 1440px) / 2 + 48px);
            }
        }
    </style>
    <div id="adiPlatformHeader">
        <div class="logo">
            <img src="https://alcon.widen.net/content/pmxiwthkgx/webp/Adi_headerlogo.webp?position=c&quality=80&u=uxrrnh&use=ibhcw"
                alt="Adi Online Store">
        </div>
        <div class="accountButton">
            <div class="profileIcon">
                <div class="dropdown">
                    <button class="dropbtn">
                        <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="22"
                                viewBox="0 0 18 22"
                                fill="none"
                        >
                            <path
                                    d="M17.75 20.5455C17.75 21.0731 17.3152 21.5 16.7778 21.5C16.2404 21.5 15.8056 21.0731 15.8056 20.5455C15.8056 16.8559 12.7579 13.8636 9 13.8636C5.24212 13.8636 2.19444 16.8559 2.19444 20.5455C2.19444 21.0731 1.7596 21.5 1.22222 21.5C0.684841 21.5 0.25 21.0731 0.25 20.5455C0.25 15.8007 4.16738 11.9545 9 11.9545C13.8326 11.9545 17.75 15.8007 17.75 20.5455ZM9 11C6.04726 11 3.65278 8.64905 3.65278 5.75C3.65278 2.85095 6.04726 0.5 9 0.5C11.9527 0.5 14.3472 2.85095 14.3472 5.75C14.3472 8.64905 11.9527 11 9 11ZM9 9.09091C10.8799 9.09091 12.4028 7.59571 12.4028 5.75C12.4028 3.90429 10.8799 2.40909 9 2.40909C7.12011 2.40909 5.59722 3.90429 5.59722 5.75C5.59722 7.59571 7.12011 9.09091 9 9.09091Z"
                                    fill="white"
                            />
                        </svg>
                    </button>
                    <div id="myDropdown" class="dropdown-content">
                        <a class="logoutButton" href="javascript:void(0)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <mask id="mask0_8895_43993" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                                      width="20" height="20">
                                    <rect width="20" height="20" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_8895_43993)">
                                    <path
                                            d="M11.6668 15L10.5002 13.7917L13.4585 10.8333H3.3335V9.16667H13.4585L10.5002 6.20833L11.6668 5L16.6668 10L11.6668 15Z"
                                            fill="#003595" />
                                </g>
                            </svg>
                            <span>Log out</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>`;
  }
  updateNotificationCount() {
    try {
      let notificationCount = 0;
      this.headerService.getNotificationCount(this.host, this.oktaAuth.getAccessToken(), this.source).subscribe({
        next: res => {
          // notificationCount = res.total;
          notificationCount = res.unread;
          this.notificationCount = res.unread;
          if (notificationCount > 0) {
            const elements = this.elementRef.nativeElement.querySelector('.notificationCount');
            this.renderer.setProperty(elements, 'textContent', notificationCount.toString());
            this.renderer.removeClass(elements, 'hideDom'), this.renderer.addClass(elements, 'showDom');
          }
          this.cd.detectChanges();
        }
      });
    } catch (err) {
      console.error('Error fetching notification count:', err);
    }
  }
  createEventListenersForAuthorized() {
    setTimeout(() => {
      const menuToggle = document.querySelector('.menu-toggle');
      if (menuToggle) {
        this.renderer.listen(document.querySelector('.menu-toggle'), 'click', () => {
          this.toggleModulesDropdown();
          this.cd.detectChanges();
        });
        this.renderer.listen(document.querySelector('.nav-cross'), 'click', () => {
          this.toggleModulesDropdown();
          this.cd.detectChanges();
        });
      }
      const appLinks = this.elementRef.nativeElement.querySelectorAll('.app-link');
      appLinks.forEach(link => {
        this.renderer.listen(link, 'click', event => {
          this.handleClick(event);
        });
      });
    }, 1000);
  }
  handleClick(event) {
    // Prevent default anchor tag behavior
    event.preventDefault();
    // Get href attribute of the clicked anchor tag
    const targetElement = event.target;
    let href = targetElement.getAttribute('href');
    const target = targetElement.getAttribute('target');
    this.emitLoader(true);
    const logObj = {
      eventType: 'Navigation',
      eventDetail: `Navigating the user to ${href}`,
      logLevel: LogLevel.INFO,
      applicationName: this.source
    };
    this.headerService.loggingApiCall(this.host, logObj).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: res => {
        this.emitLoader(false);
        this.navigateToLink(href, target);
      },
      error: err => {
        if (err.status === 401) {
          this.emitLoader(true);
          this.emitLogout();
        }
        this.emitLoader(false);
        this.navigateToLink(href, target);
      }
    });
  }
  navigateToLink(href, target) {
    localStorage.setItem('adi-sister-module-source', window.location.pathname);
    // Below condition is to open https even in native instead
    if (href && href !== '' && href !== '#') {
      if (Capacitor.isNativePlatform()) {
        //Below condition is to open href as is in case it starts with https (for ex - in case of online store)
        if (href.startsWith('https')) {
          window.open(href, '_self');
        } else {
          const mobileHref = `${window.location.origin}${href}`;
          const mobileHrefParts = mobileHref.split(':');
          mobileHrefParts[0] = 'https';
          const newMobilehref = mobileHrefParts.join(':');
          window.open(newMobilehref, '_self');
        }
      } else {
        window.open(href, target);
      }
    }
  }
  toggleModulesDropdown() {
    let headerMenu = document.getElementById('headerMenu');
    let menuIcon = document.querySelector('.menu-icon');
    if (headerMenu.style.display === 'flex') {
      headerMenu.style.display = 'none';
      menuIcon.classList.remove('active');
    } else {
      headerMenu.style.display = 'flex';
      menuIcon.classList.add('active');
    }
  }
  createEventListeners() {
    setTimeout(() => {
      // Listen for click on .logoutButton
      this.renderer.listen(document.querySelector('.logoutButton'), 'click', () => {
        this.emitLoader(true);
        this.emitLogout();
        this.cd.detectChanges();
      });
      // Listen for click on profileMenuButton
      this.renderer.listen(document.querySelector('.profileIcon'), 'click', () => {
        document?.getElementById('myDropdown')?.classList.toggle('show');
      });
      if (document.querySelector('.back-icon')) {
        this.renderer.listen(document.querySelector('.back-icon'), 'click', () => {
          this.notificationCountClicked = false;
        });
      }
      // Listen for click on notificationIcon
      this.renderer.listen(document.querySelector('.notification'), 'click', () => {
        this.notificationCountClicked = !this.notificationCountClicked;
        if (window.innerWidth <= 600 && this.notificationCountClicked) {
          document.querySelector('logo')?.classList.add('hidden');
          document.querySelector('profileIcon')?.classList.add('hidden');
          document.querySelector('bell-icon')?.classList.add('hidden');
          document.querySelector('notification')?.classList.add('hidden');
          document.querySelector('notificationCount')?.classList.add('hidden');
          document.querySelector('view-all-notification')?.classList.add('hidden');
          document.querySelector('mobile-view-all')?.classList.add('hidden');
          const dropdown = document.querySelector('dropdown-container');
          if (dropdown) {
            dropdown.style.borderRadius = 'none';
            dropdown.style.top = '70px';
          }
        } else {
          document.querySelector('logo')?.classList.remove('hidden');
          document.querySelector('profileIcon')?.classList.remove('hidden');
          document.querySelector('bell-icon')?.classList.remove('hidden');
          document.querySelector('notification')?.classList.remove('hidden');
          document.querySelector('notificationCount')?.classList.remove('hidden');
          document.querySelector('view-all-notification')?.classList.remove('hidden');
          document.querySelector('mobile-view-all')?.classList.remove('hidden');
          const dropdown = document.querySelector('dropdown-container');
          if (dropdown) {
            dropdown.style.borderRadius = '12px';
            dropdown.style.top = '35px';
          }
        }
        this.showNotificationLoader();
        if (this.notificationCount > 0) {
          this.showNotificationList();
        } else {
          this.noNotificationMsg();
          document.querySelector('view-all-notification')?.setAttribute('style', 'display: none !important;');
        }
        document?.getElementById('notificationDropdown')?.classList.toggle('display');
      });
      if (document.querySelector('sm-header')) {
        this.renderer.listen(document.querySelector('sm-header'), 'click', () => {
          document.querySelector('logo')?.removeAttribute('style');
          document.querySelector('profileIcon')?.removeAttribute('style');
          document.querySelector('bell-icon')?.removeAttribute('style');
          document.querySelector('notification')?.removeAttribute('style');
          document.querySelector('notificationCount')?.removeAttribute('style');
          document.querySelector('view-all-notification')?.removeAttribute('style');
          document.querySelector('dropdown-container')?.removeAttribute('style');
        });
      }
    }, 1000);
  }
  toggleSettings() {
    const accountOptionsContainer = document.querySelector('.accountOptions');
    accountOptionsContainer?.classList.toggle('show');
  }
  showNotificationLoader() {
    const container = this.elementRef.nativeElement.querySelector('#notificationDropdown');
    if (!container) return;
    if (container) {
      container.innerHTML = '';
      // Create notification header
      const notificationHeader = this.renderer.createElement('div');
      this.renderer.addClass(notificationHeader, 'notifications-header');
      notificationHeader.innerHTML = '';
      if (window.innerWidth < 768) {
        notificationHeader.innerHTML += `
        <div class="back-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_22522_89173)">
                  <path d="M17.51 3.8701L15.73 2.1001L5.84 12.0001L15.74 21.9001L17.51 20.1301L9.38 12.0001L17.51 3.8701Z" fill="white"/>
              </g>
              <defs>
                  <clipPath id="clip0_22522_89173">
                      <rect width="24" height="24" fill="white"/>
                  </clipPath>
              </defs>
          </svg>
        </div>
        `;
      }
      notificationHeader.innerHTML += `
        <div class="notifications-title">Notifications</div>
        <div class="view-all-notification"><a href="/${localStorage.getItem('adi-selected-locale')}/notifications" class="view-all-link">View all</a></div>`;
      this.renderer.appendChild(container, notificationHeader);
      const notificationDiv = this.renderer.createElement('div');
      this.renderer.addClass(notificationDiv, 'notification-dropdown');
      notificationDiv.innerHTML = `
          <div class="loader-container showLoader">
            <div class="spinner"></div>
            <div class="loading-text">Please wait while we fetching your data</div>
          </div>
        `;
      this.renderer.appendChild(container, notificationDiv);
    }
  }
  hideNotificationLoader() {
    const loaderContainer = this.elementRef.nativeElement.querySelector('#notificationDropdown .notification-dropdown .loader-container');
    this.renderer.removeClass(loaderContainer, 'showDom');
    this.renderer.addClass(loaderContainer, 'hideDom');
  }
  showNotificationList() {
    this.headerService.getNotificationList(this.host, this.oktaAuth.getAccessToken(), this.source).subscribe({
      next: res => {
        if (res.length > 0) {
          this.notifications = res;
          this.hideNotificationLoader();
          this.insertNotifications();
        } else {
          this.hideNotificationLoader();
          this.noNotificationMsg();
        }
      },
      error: () => {}
    });
  }
  noNotificationMsg() {
    const container = this.elementRef.nativeElement.querySelector('#notificationDropdown');
    if (container) {
      container.innerHTML = '';
      const notificationHeader = this.renderer.createElement('div');
      this.renderer.addClass(notificationHeader, 'notifications-header');
      const notificationsUrl = `${window.location.origin}/${localStorage.getItem('adi-selected-locale')}/notifications`;
      notificationHeader.innerHTML = '';
      if (window.innerWidth < 768) {
        notificationHeader.innerHTML += `
        <div class="back-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_22522_89173)">
                  <path d="M17.51 3.8701L15.73 2.1001L5.84 12.0001L15.74 21.9001L17.51 20.1301L9.38 12.0001L17.51 3.8701Z" fill="white"/>
              </g>
              <defs>
                  <clipPath id="clip0_22522_89173">
                      <rect width="24" height="24" fill="white"/>
                  </clipPath>
              </defs>
          </svg>
        </div>
        `;
      }
      notificationHeader.innerHTML += `
      <div class="notifications-title">Notifications</div>
      <div class="view-all-notification"><a href="${notificationsUrl}" class="view-all-link">View all</a></div>`;
      this.renderer.appendChild(container, notificationHeader);
      const notificationDiv = this.renderer.createElement('div');
      this.renderer.addClass(notificationDiv, 'notification-dropdown');
      this.renderer.addClass(notificationDiv, 'no-notification-dropdown');
      notificationDiv.innerHTML = `<div class="body">You have no recent notifications</div><br>`;
      if (window.innerWidth < 768) {
        // Create notification mobile view all
        const mobileViewAllLink = this.renderer.createElement('div');
        this.renderer.addClass(mobileViewAllLink, 'mobile-view-all');
        mobileViewAllLink.innerHTML = `
          <div class='viewAll'><a href="${notificationsUrl}" class="view-all-link">View all</a></div></div>
        `;
        this.renderer.appendChild(container, mobileViewAllLink);
        this.cd.detectChanges();
      }
      this.renderer.appendChild(container, notificationDiv);
    }
  }
  insertNotifications() {
    const container = this.elementRef.nativeElement.querySelector('#notificationDropdown');
    if (!container) return;
    container.innerHTML = '';
    // Create notification header
    const notificationHeader = this.renderer.createElement('div');
    this.renderer.addClass(notificationHeader, 'notifications-header');
    const notificationsUrl = `${window.location.origin}/${localStorage.getItem('adi-selected-locale')}/notifications`;
    notificationHeader.innerHTML = '';
    if (window.innerWidth < 768) {
      notificationHeader.innerHTML += `
        <div class="back-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_22522_89173)">
                  <path d="M17.51 3.8701L15.73 2.1001L5.84 12.0001L15.74 21.9001L17.51 20.1301L9.38 12.0001L17.51 3.8701Z" fill="white"/>
              </g>
              <defs>
                  <clipPath id="clip0_22522_89173">
                      <rect width="24" height="24" fill="white"/>
                  </clipPath>
              </defs>
          </svg>
        </div>
        `;
    }
    notificationHeader.innerHTML += `
      <div class="notifications-title">Notifications</div>
      <div class="view-all-notification"><a href="${notificationsUrl}" class="view-all-link">View all</a></div>`;
    this.renderer.appendChild(container, notificationHeader);
    this.notifications.forEach(notification => {
      const date = this.formatNotificationDate(notification);
      // Determine read/unread state and assign classes
      const isUnread = notification.read === false;
      const notificationBodyClass = isUnread ? 'unread-body' : 'read-body';
      const titleClass = isUnread ? 'unreadtitle' : 'readtitle';
      const unreadIcon = isUnread ? `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                 <circle cx="4" cy="4" r="4" fill="#003595" />
               </svg>` : '';
      // Create notification div with severity class
      const notificationDiv = this.renderer.createElement('div');
      this.renderer.addClass(notificationDiv, 'notification-dropdown');
      this.renderer.addClass(notificationDiv, notification.severity);
      this.renderer.addClass(notificationDiv, notificationBodyClass);
      notificationDiv.innerHTML = `
        <div class="notification-body">
          <div class="icon-column">
            ${notification.severity === 'WARN' ? this.getWarningIcon() : '<div class="empty-icon"></div>'}
          </div>
          <div class="text-column">
            <div class="title ${titleClass}">
              ${notification.title}
            </div>
            <div class="body">
              ${notification.body}
            </div>
            <div class="content">
              <div class="time">
                ${unreadIcon ? `<div class="unread-icon">${unreadIcon}</div>` : ''}
                <div class="date">${date}</div>
              </div>
            </div>
          </div>
        </div>`;
      this.renderer.listen(notificationDiv, 'click', event => {
        if (!notification.read) {
          this.readNotification(notification);
        } else {
          this.handleNotificationClick(notification);
        }
      });
      this.renderer.appendChild(container, notificationDiv);
      this.cd.detectChanges();
    });
    if (window.innerWidth < 768) {
      // Create notification mobile view all
      const mobileViewAllLink = this.renderer.createElement('div');
      this.renderer.addClass(mobileViewAllLink, 'mobile-view-all');
      mobileViewAllLink.innerHTML = `
        <div class='viewAll'><a href="${notificationsUrl}" class="view-all-link">View all</a></div></div>
      `;
      this.renderer.appendChild(container, mobileViewAllLink);
      this.cd.detectChanges();
    }
  }
  getWarningIcon() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M7.20563 1.88262C7.4473 1.74127 7.72093 1.6665 8 1.6665C8.27907 1.6665 8.5527 1.74127 8.79437 1.88262C9.03594 2.02391 9.23689 2.22661 9.37897 2.46995L9.37989 2.47152L14.787 11.8492C14.9261 12.0994 14.9992 12.3822 15 12.6695C15.0008 12.9568 14.9292 13.24 14.7914 13.491C14.6537 13.7421 14.4542 13.9529 14.2115 14.1012C13.9688 14.2496 13.6918 14.3299 13.4083 14.3331L13.4045 14.3332L2.59166 14.3332C2.30815 14.3299 2.0312 14.2496 1.78845 14.1012C1.54581 13.9529 1.34631 13.7421 1.20856 13.491C1.07085 13.24 0.999232 12.9568 1.00001 12.6695C1.00078 12.3822 1.07392 12.0995 1.213 11.8492L1.21555 11.8447L6.62011 2.47152L6.62103 2.46995C6.76311 2.22661 6.96406 2.02391 7.20563 1.88262ZM7.53337 5.70235H8.4667V10.3615H7.53337V5.70235ZM7.53337 11.0979H8.4667V11.9801H7.53337V11.0979Z"
          fill="#FF6B12" />
      </svg>`;
  }
  formatNotificationDate(notification) {
    const currentDate = new Date();
    const currentDateUtc = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds()));
    const notificationDate = typeof notification.createdAt === 'string' ? new Date(notification.createdAt) : notification.createdAt;
    const isSameDay = currentDateUtc.getFullYear() === notificationDate.getFullYear() && currentDateUtc.getMonth() === notificationDate.getMonth() && currentDateUtc.getDate() === notificationDate.getDate();
    if (isSameDay) {
      return 'Today';
    } else {
      const dateString = notification.createdAt.split('T')[0];
      const [year, month, day] = dateString.split('-');
      const date = new Date(`${year}-${month}-${day}`);
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const weekday = daysOfWeek[date.getDay()];
      const monthName = date.toLocaleString('default', {
        month: 'long'
      });
      return `${weekday}, ${day} ${monthName}, ${year}`;
    }
  }
  handleNotificationClick(notificationData) {
    if (notificationData.action && notificationData.action.url) {
      window.location.href = notificationData.action.url;
    } else {
      this.router.navigate([`/${localStorage.getItem('adi-selected-locale')}/notifications`]);
    }
  }
  readNotification(notification) {
    const notificationData = {
      id: notification.id,
      read: true
    };
    this.headerService.readNotification(this.host, this.oktaAuth.getAccessToken(), this.source, notificationData).subscribe({
      next: res => {
        this.handleNotificationClick(notification);
        this.updateNotificationsList.emit();
      },
      error: error => {
        this.handleNotificationClick(notification);
      }
    });
  }
  updateNotificationsData() {
    this.updateNotificationCount();
    this.showNotificationList();
  }
  //communication with app
  emitLogout() {
    //API CALL
    const logObj = {
      eventType: 'Log Out',
      eventDetail: 'User has triggered logout',
      logLevel: LogLevel.INFO,
      applicationName: this.source
    };
    this.headerService.loggingApiCall(this.host, logObj).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: res => {
        this.navigateToLogout();
      },
      error: err => {
        this.navigateToLogout();
      }
    });
  }
  navigateToLogout() {
    window.location.href = `${window.location.origin}/${localStorage.getItem('adi-selected-locale')}/logout`;
  }
  emitLoader(val) {
    this.loader.emit(val);
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  static {
    this.ɵfac = function AdiHeaderComponent_Factory(t) {
      return new (t || AdiHeaderComponent)(i0.ɵɵdirectiveInject(AdiPlatformHeaderService), i0.ɵɵdirectiveInject(i2.DomSanitizer), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i3.Router));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AdiHeaderComponent,
      selectors: [["adi-header-lib"]],
      hostBindings: function AdiHeaderComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("scroll", function AdiHeaderComponent_scroll_HostBindingHandler() {
            return ctx.onWindowScroll();
          }, false, i0.ɵɵresolveWindow)("click", function AdiHeaderComponent_click_HostBindingHandler($event) {
            return ctx.clickOutside($event);
          }, false, i0.ɵɵresolveDocument)("resize", function AdiHeaderComponent_resize_HostBindingHandler($event) {
            return ctx.onResize($event);
          }, false, i0.ɵɵresolveWindow);
        }
      },
      inputs: {
        host: "host",
        source: "source",
        env: "env"
      },
      outputs: {
        loader: "loader",
        updateNotificationsList: "updateNotificationsList"
      },
      decls: 1,
      vars: 1,
      consts: [[3, "innerHTML"]],
      template: function AdiHeaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("innerHTML", ctx.htmlContent, i0.ɵɵsanitizeHtml);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AdiHeaderComponent, [{
    type: Component,
    args: [{
      selector: 'adi-header-lib',
      template: `<div [innerHTML]="htmlContent"></div>`
    }]
  }], () => [{
    type: AdiPlatformHeaderService
  }, {
    type: i2.DomSanitizer
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i3.Router
  }], {
    host: [{
      type: Input
    }],
    source: [{
      type: Input
    }],
    env: [{
      type: Input
    }],
    loader: [{
      type: Output,
      args: ['loader']
    }],
    updateNotificationsList: [{
      type: Output,
      args: ['updateNotificationsList']
    }],
    onWindowScroll: [{
      type: HostListener,
      args: ['window:scroll', []]
    }],
    onClick: [{
      type: HostListener,
      args: ['document:click', ['$event']]
    }],
    onResize: [{
      type: HostListener,
      args: ['window:resize', ['$event']]
    }],
    clickOutside: [{
      type: HostListener,
      args: ['document:click', ['$event']]
    }]
  });
})();
class AdiHeaderModule {
  static {
    this.ɵfac = function AdiHeaderModule_Factory(t) {
      return new (t || AdiHeaderModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AdiHeaderModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [AdiPlatformHeaderService],
      imports: [CommonModule, HttpClientModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AdiHeaderModule, [{
    type: NgModule,
    args: [{
      declarations: [AdiHeaderComponent],
      imports: [CommonModule, HttpClientModule],
      exports: [AdiHeaderComponent],
      providers: [AdiPlatformHeaderService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of header
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AdiHeaderComponent, AdiHeaderModule };
